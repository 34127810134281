
















































































































































































































































































































































































































































import { DisplayBoolean } from "@/components/DisplayBoolean";
import { DisplayTotal, FieldPolicyNumber } from "@/components/Leasing";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RowAsset, State } from "@/store/account-payable/leasing/types";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";

@Component({
  components: {
    DisplayTotal,
    FieldPolicyNumber,
    DisplayBoolean,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.leasingStore,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters({
      isIdr: "leasingStore/isIdr",
      isSourceCar: "leasingStore/isSourceCar",
    }),
  },
})
export default class DetailPage extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;

  store!: State;
  storeBaseDecimalPlace!: number;
  isIdr!: boolean;

  columnsAsset = [
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "assetUnitCode",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_dealer_name"),
      dataIndex: "dealer",
      key: "dealer",
      customRender: (_, record: RowAsset): string =>
        record.dealer?.label || "-",
    },
    {
      title: this.$t("lbl_tax_invoice_number_dp"),
      dataIndex: "taxInvoiceNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_prepayment_date"),
      dataIndex: "prepaymentDate",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_tax_invoice_number_payment"),
      dataIndex: "paymentTaxInvoiceNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_date_payment_invoice"),
      dataIndex: "paymentTaxInvoiceDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_repayment_percentage"),
      dataIndex: "portion",
      scopedSlots: { customRender: "percent" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "brand",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_cost_price_unit"),
      dataIndex: "costPricePerUnit",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_nett_finance"),
      dataIndex: "nettFinance",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_interest"),
      dataIndex: "interest",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_provision"),
      dataIndex: "provision",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_insurance"),
      dataIndex: "insurancePremium",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_residue"),
      dataIndex: "residue",
      scopedSlots: { customRender: "currency" },
    },
  ];

  columnsPeriod = [
    {
      title: this.$t("lbl_billing_period"),
      dataIndex: "installment",
      fixed: "left",
      width: "150px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_payment_number"),
      dataIndex: "chequeNumber",
      fixed: "left",
      width: "200px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_payment_type"),
      dataIndex: "paymentType",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_payment_date"),
      dataIndex: "paymentDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_principal_cost"),
      dataIndex: "principalCost",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_interest_cost"),
      dataIndex: "interestCost",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_installment"),
      dataIndex: "paymentCost",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_balance"),
      dataIndex: "balance",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_realization"),
      dataIndex: "paymentRealizationDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_document_reference"),
      dataIndex: "cashInOutDocumentNumber",
      scopedSlots: { customRender: "cashInOutDocumentNumber" },
    },
  ];

  handleBack(): void {
    this.$router.push({ name: "account-payables.leasing" });
  }
}
